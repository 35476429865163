import React from "react"
import Layout from "../../components/Layout"
import styled from "styled-components"
import RequestDemoForm from "../../components/individualProductPage/RequestDemoForm"
import StealBackground from "../../components/individualProductPage/images/steal-background.jpg"
import SpecBarMachineImage from "../../components/individualProductPage/images/pilot-cylindrical-profile.529.png"
import UsaBackground from "../../components/individualProductPage/images/handmade-in-usa-banner-pilot.jpg"
import MachineOverviewImage from "../../components/individualProductPage/images/pilot-machineoverview.jpg"
import HeavyDutyBackground from "../../components/individualProductPage/images/heavy-duty-banner-pilot.jpg"
import MachineDimensionImage from "../../components/individualProductPage/images/pilot-dims.jpg"
import RideOnTBTJ from "../../../static/FC-RIDER-YBTJ-LANDSCAPE.pdf"

//Thumbnail Images
import CylindricalThumb1Image from "../../components/individualProductPage/images/pilot-29C-1.jpg"
import CylindricalThumb2Image from "../../components/individualProductPage/images/pilot-29C-4.jpg"
import CylindricalThumb3Image from "../../components/individualProductPage/images/pilot-29C-3.jpg"
import CylindricalThumb4Image from "../../components/individualProductPage/images/pilot-29C-2.jpg"
import CylindricalThumb5Image from "../../components/individualProductPage/images/pilot-29C-5.jpg"
import DiskThumb1 from "../../components/individualProductPage/images/pilot-28d-black-1.jpg"
import DiskThumb2 from "../../components/individualProductPage/images/pilot-28d-black-4.jpg"
import DiskThumb3 from "../../components/individualProductPage/images/pilot-28d-black-3.jpg"
import DiskThumb4 from "../../components/individualProductPage/images/pilot-28d-black-2.jpg"
import DiskThumb5 from "../../components/individualProductPage/images/pilot-28d-black-5.jpg"
//End Thumbnail Images
import "../product-page-styles.scss"

// ORBITAL IMAGES
// fc-pilot-orbital-deck-view-1.1228
//Main Images
import OrbitalOne from "../../components/individualProductPage/images/fc-pilot-orbital-deck-view-1.1228.png"
import OrbitalTwo from "../../components/individualProductPage/images/fc-pilot-orbital-deck-view-2.1229.png"
import OrbitalThree from "../../components/individualProductPage/images/fc-pilot-orbital-deck-view-3.1230.png"
import OrbitalFour from "../../components/individualProductPage/images/fc-pilot-orbital-deck-view-4.1231.png"
import OrbitalFive from "../../components/individualProductPage/images/fc-pilot-orbital-deck-view-5.1232.png"
import CylindricalMain1Image from "../../components/individualProductPage/images/pilot-29C-1.jpg"
import CylindricalMain2Image from "../../components/individualProductPage/images/pilot-29C-4.jpg"
import CylindricalMain3Image from "../../components/individualProductPage/images/pilot-29C-3.jpg"
import CylindricalMain4Image from "../../components/individualProductPage/images/pilot-29C-2.jpg"
import CylindricalMain5Image from "../../components/individualProductPage/images/pilot-29C-5.jpg"
import DiskMain1Image from "../../components/individualProductPage/images/pilot-28d-black-1.jpg"
import DiskMain2Image from "../../components/individualProductPage/images/pilot-28d-black-4.jpg"
import DiskMain3Image from "../../components/individualProductPage/images/pilot-28d-black-3.jpg"
import DiskMain4Image from "../../components/individualProductPage/images/pilot-28d-black-2.jpg"
import DiskMain5Image from "../../components/individualProductPage/images/pilot-28d-black-5.jpg"
import MediaTile from "../../components/individualProductPage/MediaTile"
import MainSpecBar from "../../components/individualProductPage/FeaturedProduct/MainSpecBar"
import MadeInUSA from "../../components/individualProductPage/MadeInUSA"
import SectionTitle from "../../components/common/SectionTitle"
import MachineOverview from "../../components/individualProductPage/MachineOverview"
import HeavyDuty from "../../components/individualProductPage/HeavyDuty"
import MachineDimensions from "../../components/individualProductPage/MachineDimensions"

import ControllerImage from "../../components/individualProductPage/images/choose-your-controller-box-riders-v2.jpg"

import { OptionsBar } from "../../components/individualProductPage/FeaturedProduct/FeaturedProductStyles/OptionsBar"
//End Main Images

import SEO from "../../components/SEO"
import Head from "../../components/Head"
import IndividualMachine from "../../components/individualProductPage/individualMachine"

const MediaTilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 840px) {
    max-width: 650px;
  }
`
const ControllerImageContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`

const SizeBtnContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
`

const PilotProductPage = props => {
  return (
    <>
      <Layout>
        <SEO title="Pilot v2.1" />
        <Head title="Pilot v2.1" />
        <IndividualMachine
          cylindricalThumb1={CylindricalThumb1Image}
          cylindricalThumb2={CylindricalThumb2Image}
          cylindricalThumb3={CylindricalThumb3Image}
          cylindricalThumb4={CylindricalThumb4Image}
          cylindricalThumb5={CylindricalThumb5Image}
          cylindricalMain1={CylindricalMain1Image}
          cylindricalMain2={CylindricalMain2Image}
          cylindricalMain3={CylindricalMain3Image}
          cylindricalMain4={CylindricalMain4Image}
          cylindricalMain5={CylindricalMain5Image}
          diskThumb1={DiskThumb1}
          diskThumb2={DiskThumb2}
          diskThumb3={DiskThumb3}
          diskThumb4={DiskThumb4}
          diskThumb5={DiskThumb5}
          diskMain1={DiskMain1Image}
          diskMain2={DiskMain2Image}
          diskMain3={DiskMain3Image}
          diskMain4={DiskMain4Image}
          diskMain5={DiskMain5Image}
          orbitalThumb1={OrbitalOne}
          orbitalThumb2={OrbitalTwo}
          orbitalThumb3={OrbitalThree}
          orbitalThumb4={OrbitalFour}
          orbitalThumb5={OrbitalFive}
          orbitalMain1={OrbitalOne}
          orbitalMain2={OrbitalTwo}
          orbitalMain3={OrbitalThree}
          orbitalMain4={OrbitalFour}
          orbitalMain5={OrbitalFive}
          brochureLink="https://www.dropbox.com/s/u77dwgm4t3xffk6/FC-V2-RIDERS-SM.pdf?dl=0"
          techLink="/fatorycat-rider-v2-techspec.pdf"
          partsLink="/FC-PILOT-V2-1-P-EN.pdf"
          opLink="/FC-RIDERS-V2-1-OP-EN.pdf"
          title="PILOT v2.1 - Ride On Floor Scrubber"
          textContent="Built for maneuverability our Pilot-HD battery powered micro ride-on floor scrubber is the toughest in class.  Built on a solid steel, welded frame, solid rear axle and oversized tires the Pilot-HD stands alone. The small footprint and low psi on the cleaning surface allowing staff to clean in places most ride-on scrubbers cannot go.  With the operator onboard the machine it is more maneuverable than a large walk behind auto scrubber.  At only 48” (122cm) long and 24” (61cm) wide the Pilot-HD floor scrubber will fit easily on most elevators, through most doorways and turn around in narrow hallways or aisles."
          mobileTitle="PILOT v2.1 - Ride On Floor Scrubber"
          pageUrl=""
          specifications="Cleaning Path: 25-29 | Run Time: Up To 4.0 Hours | Tank Capacity: Sol: 22 Gal, Rec: 23 Gal"
          videoSource="https://www.youtube.com/embed/NaGoDJxcSGM"
          upLink="/ride-on-floor-scrubbers/gtx"
          machineName="Pilot v2.1"
          downLink="/walk-behind-floor-scrubbers/mag-hd"
          pmLink="/Rider-V2-PM.pdf"
          mtLink="https://www.dropbox.com/s/sx03160k2ppabfl/daily-weekly-maintenance-premium-v21306.pdf?dl=0"
        />
        <div className="tiles-container">
          <MediaTile
            title="Why FactoryCat ?"
            subTitle="Walk Behind Floor Scrubbers"
            link={RideOnTBTJ}
          />

          <MediaTile
            title="Videos"
            subTitle="Walk Behind Floor Scrubbers"
            link="/videos"
          />
          <MediaTile
            link="/images#ride-on-scrubbers"
            title="Images"
            subTitle="Walk Behind Floor Scrubbers"
          />
          <MediaTile
            title="Applications"
            subTitle="Walk Behind Floor Scrubbers"
            link="/applications"
          />
        </div>
        <MainSpecBar
          bgImage={StealBackground}
          machineImage={SpecBarMachineImage}
          myColor="white"
          machineName="PILOT v2.1"
          category="Ride on Floor Scrubber"
          path='25 - 29"'
          path2="(63.5 - 73.7 cm)"
          runTime="Up To 4.0 Hours"
          solution="Solution: 22 Gal"
          solutionLiter="(83.3L)"
          recovery="Recovery: 23 Gal"
          recoveryLiter="(87L)"
        />
        <h1
          style={{
            margin: "3rem",
            textAlign: "center",
            color: "red",
            fontSize: "3em",
          }}
        >
          Choose Your Controller
        </h1>
        <ControllerImageContainer>
          <img src={ControllerImage} alt="" />
        </ControllerImageContainer>
        <MadeInUSA bgImage={UsaBackground} />
        <div className="spec-title-wrapper">
          <SectionTitle title="Machine Overview" />
        </div>
        <MachineOverview image={MachineOverviewImage} />
        <HeavyDuty
          bgImage={HeavyDutyBackground}
          innerText="HEAVY DUTY CLEANING"
        />
        <MachineDimensions
          image={MachineDimensionImage}
          sizeTitle="Size (L x W x H):"
          size='53" x 31" x 57'
          weightTitle="Weight (w/o batteries):"
          weight="525 - 575 lbs"
          voltageTitle="System Voltage:"
          voltage="36 VDC"
          stdBatteryTitle="Standard Battery:"
          battery="175 ah WET"
          powerTitle="Power:"
          power="0.75hp"
          tankTitle="Tank Material:"
          tank='Poly 3/8"'
        />
        {/* <OptionsBar>Options</OptionsBar>
        <MiniHdOptions /> */}

        <div id="demo-form"></div>
        <div className="spec-title-wrapper">
          <SectionTitle title="Request a Demo" />
        </div>

        <RequestDemoForm image={CylindricalMain1Image} />
      </Layout>
    </>
  )
}

export default PilotProductPage
